<template>
    <div>OrderName</div>
</template>

<script>
export default {
    props: {
        record: {
            type: Object,
            required: true
        }
    }
}
</script>